import { render, staticRenderFns } from "./BreadcrumbItem.vue?vue&type=template&id=3e700e7e"
import script from "./BreadcrumbItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./BreadcrumbItem.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@align/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default})
